import { Injectable } from '@angular/core';

@Injectable()
export class SearchService {
    public Status: string[] | null;
    public DateFrom: string | null;
    public kw: string | null;
    public DateTo: string | null;
    public RiskForId: number | null;
    public JobCondition: number[] | null;
    public JobOwnerId: number[] | null;
    public AreaOwnerId: number[] | null;
    public ActivityTypeId: number[] | null;
    public AreaClassification: number[] | null;
    public HazardGroupId: number[] | null;
    public SiteId: number | null;
    public LocationId: number[] | null;
    public ContractCompanyId: number | null;
    public ApproveUserId1: number | null;
    public ApproveUserId2: number | null;
    public ApproveUserId3: number | null;
    public ApproveUserId4: number | null;
    public MyTask: boolean | null;
    public History: boolean | null;
    constructor() {
        this.Status = [];
        this.DateFrom = null;
        this.DateTo = null;
        this.RiskForId = null;
        this.JobCondition = [];
        this.JobOwnerId = [];
        this.AreaOwnerId = [];
        this.ActivityTypeId = [];
        this.AreaClassification = [];
        this.HazardGroupId = [];
        this.SiteId = null;
        this.LocationId = [];
        this.ApproveUserId1 = null;
        this.ApproveUserId2 = null;
        this.ApproveUserId3 = null;
        this.ApproveUserId4 = null;
        this.ContractCompanyId = null;
        this.MyTask = false;
        this.History = false;
        this.kw = null;
    }
    clearSearch(): void {
        this.Status = [];
        this.DateFrom = null;
        this.DateTo = null;
        this.RiskForId = null;
        this.JobCondition = [];
        this.JobOwnerId = [];
        this.AreaOwnerId = [];
        this.ActivityTypeId = [];
        this.AreaClassification = [];
        this.HazardGroupId = [];
        this.SiteId = null;
        this.LocationId = [];
        this.ApproveUserId1 = null;
        this.ApproveUserId2 = null;
        this.ApproveUserId3 = null;
        this.ApproveUserId4 = null;
        this.ContractCompanyId = null;
        this.kw = null;
        this.MyTask = false;
        this.History = false;

    }

}
