import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { GlobalService } from './global.service';

@Injectable()
export class AuthGuardService {

    constructor(private router: Router, private globalService: GlobalService) { }
    canActivate(_route: ActivatedRouteSnapshot | null, state: RouterStateSnapshot): boolean {
        const token = sessionStorage.getItem('token');
        if (token && token !== 'null') {
            return true;
        } else {
            sessionStorage.removeItem('token');
            this.globalService.user = null;
            this.globalService.onTokenChange?.next(null);
            this.router.navigate(['/logon'], {
                queryParams: {
                    return: state.url
                }
            });
            return false;
        }
    }
}
