import { Injectable } from '@angular/core';
import { ConnectionService } from '@oot/connection/connection.service';
import { GlobalService } from './global.service';

@Injectable()
export class AuthenticationService {

    constructor(private connectionService: ConnectionService, private globalService: GlobalService) {

    }

    azureLogin(token: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.connectionService.post('/Auth/AzureLogin', { Token: token }).then((response: any) => {
                if (response.Token) {
                    sessionStorage.setItem('refresh_token', response.RefreshToken);
                    sessionStorage.setItem('token', response.Token);
                    this.globalService.onTokenChange?.next(response.Token);
                    resolve(true);
                }
                else {
                    throw new Error('Invalid data');
                }

            }).catch((error) => { reject(new Error(error)); });
        });
    }

    azureB2cLogin(token: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.connectionService.post('/Auth/AzureB2cLogin', { Token: token }).then((response: any) => {
                if (response.Token) {
                    sessionStorage.setItem('refresh_token', response.RefreshToken);
                    sessionStorage.setItem('token', response.Token);
                    this.globalService.onTokenChange?.next(response.Token);
                    resolve(true);
                }
                else {
                    throw new Error('Invalid data');
                }

            }).catch((error) => { reject(new Error(error)); });
        });
    }

    logout(): Promise<any> {
        return new Promise((resolve) => {
            if (sessionStorage.getItem('token')) {
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('refresh_token');
                this.globalService.user = null;
                this.globalService.onTokenChange?.next(null);
            }

            window.location.href = '';
            resolve(true);
        });
    }
}
