import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        'id': 'dashboard',
        'icon': 'apps',
        'title': 'Home',
        'translate': 'Nav.Home',
        'type': 'item',
        'url': '/dashboard'
    },
    {
        id: 'ejsa',
        title: 'e-JSA',
        translate: 'Nav.Ejsa',
        icon: 'assignment',
        type: 'collapsable',
        children: [
            {
                'id': 'ejsa-create',
                'title': 'Create JSA',
                'translate': 'Nav.CreateNew',
                'type': 'item',
                'url': '/ejsa/create'
            },
            {
                'id': 'ejsa-attachment-create',
                'title': 'Create JSA Attachment',
                'translate': 'Nav.CreateNewAttachment',
                'type': 'item',
                'url': '/ejsa/create-attachment'
            },
            {
                'id': 'ejsa-list',
                'title': 'e-JSA Data',
                'translate': 'Nav.EjsaData',
                'type': 'item',
                'url': '/ejsa/list'
            },
            {
                'id': 'matrix',
                'title': 'Risk Assessment Matrix',
                'translate': 'Risk Assessment Matrix',
                'type': 'item',
                'externalUrl': true,
                'openInNewTab': true,
                'url': '/assets/documents/matrix.pdf'
            },
            {
                'id': 'tbt',
                'title': 'Toolbox Talk Form',
                'type': 'item',
                'externalUrl': true,
                'openInNewTab': true,
                'url': '/assets/documents/tbt.pdf'
            },
            {
                'id': 'jsa-template',
                'title': 'JSA Form',
                'type': 'item',
                'externalUrl': true,
                'openInNewTab': true,
                'url': '/assets/documents/jsa-form.docx'
            },
        ]
    },
    {
        'id': 'mytask',
        'title': 'My Task',
        'icon': 'business_center',
        'translate': 'Nav.MyTask',
        'type': 'item',
        'url': '/my-task',
        'badge': {
            bg: '#ff0000',
            fg: '#ffffff',
            title: ''
        }
    },
    {
        'id': 'ejsasearch',
        'icon': 'search',
        'title': 'Search',
        'translate': 'Nav.EjsaSearch',
        'type': 'item',
        'url': '/search'
    },
    {
        'id': 'taskhistory',
        'icon': 'history',
        'title': 'My Records',
        'translate': 'Nav.TaskHistory',
        'type': 'item',
        'url': '/task-history'
    },
    {
        id: 'master-data',
        title: 'Master Data',
        translate: 'Nav.MasterData',
        icon: 'donut_large',
        type: 'group',
        children: [
            {
                'id': 'site',
                'title': 'Site',
                'translate': 'Nav.Site',
                'type': 'item',
                'url': '/master/site'
            },
            {
                'id': 'location',
                'title': 'Area Authority',
                'translate': 'Nav.Area',
                'type': 'item',
                'url': '/master/location'
            },
            {
                'id': 'activityType',
                'title': 'Activity Type',
                'translate': 'Nav.ActivityType',
                'type': 'item',
                'url': '/master/activity-type'
            },
            {
                'id': 'hazard',
                'title': 'Hazard',
                'translate': 'Nav.Hazard',
                'type': 'item',
                'url': '/master/hazard'
            },
            {
                'id': 'barrier',
                'title': 'Barriers',
                'translate': 'Nav.Barriers',
                'type': 'item',
                'url': '/master/barrier'
            },
            {
                'id': 'life-saving-rule',
                'title': 'LSR/PSR',
                'translate': 'Nav.LifeSavingRule',
                'type': 'item',
                'url': '/master/life-saving-rule'
            },
            {
                'id': 'contract-company',
                'title': 'Contractor Company Name',
                'translate': 'Nav.ContractCompany',
                'type': 'item',
                'url': '/master/contract-company'
            },
        ]
    }
    ,
    {
        'id': 'configure',
        'title': 'Configure',
        'translate': 'Nav.Configure',
        'icon': 'settings',
        'type': 'collapsable',
        'url': '/configure'
    }
    ,
    {
        'id': 'users',
        'title': 'System User',
        'translate': 'Nav.SystemUser',
        'icon': 'lock',
        'type': 'collapsable',
        'url': '/user'
    }
];
