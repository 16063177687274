/* eslint-disable @typescript-eslint/prefer-for-of */
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { TranslateService } from '@ngx-translate/core';
import { locale as navigationEnglish } from 'app/main/i18n/en';
import { locale as navigationThai } from 'app/main/i18n/th';
import { navigation } from 'app/navigation/navigation';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { EnvironmentService } from './services/env.service';
import { MyMonitoringService } from './services/logging.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    appInsights: ApplicationInsights;
    instrumentationKey: string;
    // Private
    private _unsubscribeAll: Subject<any>;
    private _fuseConfigService: FuseConfigService;
    private _fuseNavigationService: FuseNavigationService;
    private _fuseSidebarService: FuseSidebarService;
    private _fuseTranslationLoaderService: FuseTranslationLoaderService;
    private _translateService: TranslateService;
    private _platform: Platform;
    private _route: ActivatedRoute;
    private _router: Router;
    private _envService: EnvironmentService;
    private _myMonitoringService: MyMonitoringService;
    constructor(
        @Inject(DOCUMENT) private document: any,
        injector: Injector
    ) {

        this._envService = injector.get<EnvironmentService>(EnvironmentService);
        this._fuseConfigService = injector.get<FuseConfigService>(FuseConfigService);
        this._fuseNavigationService = injector.get<FuseNavigationService>(FuseNavigationService);
        this._fuseSidebarService = injector.get<FuseSidebarService>(FuseSidebarService);
        this._fuseTranslationLoaderService = injector.get<FuseTranslationLoaderService>(FuseTranslationLoaderService);
        this._translateService = injector.get<TranslateService>(TranslateService);
        this._platform = injector.get<Platform>(Platform);
        this._route = injector.get<ActivatedRoute>(ActivatedRoute);
        this._router = injector.get<Router>(Router);
        this._myMonitoringService = injector.get<MyMonitoringService>(MyMonitoringService);

        // Get default navigation

        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'th']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationThai);

        // Use a language

        this._route.queryParams.subscribe((params) => {
            if (params.lang && (params.lang === 'th' || params.lang === 'en')) {
                this._translateService.use(params.lang);
            }

        });



        this._translateService.use('en');
        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.loadAppInsights();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;
                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                }
                else {
                    this.document.body.classList.remove('boxed');
                }


                for (const className of Array.from(this.document.body.classList)) {
                    if (typeof className == 'string' && className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });

            this._fuseConfigService.config = {
                layout: {
                  navbar: {
                    hidden: true
                  },
                  toolbar: {
                    hidden: true
                  },
                  footer: {
                    hidden: true
                  },
                  sidepanel: {
                    hidden: true
                  }
                }
              };
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key)?.toggleOpen();
    }

    private loadAppInsights(): void {
        this._envService.get().subscribe(() => {
            this.instrumentationKey = this._envService.getConfig().instrumentationKey;
            this._myMonitoringService.setinstrumentationKey(this.instrumentationKey);
            this._myMonitoringService.loadAppInsights();
            this.trackPageView();
        });
    }

    private trackPageView(): void {
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this._myMonitoringService.logPageView('EJSA', event.urlAfterRedirects);
        });
    }
}
