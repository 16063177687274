export const locale = {
    lang: 'th',
    data: {
        'View e-JSA':'ดูรายงาน JSA',
        'View e-JSA Revision':'ดูประวัติรายงาน JSA',
        'dataNotFound': 'ไม่พบข้อมูล',
        'select': 'เลือก',
        'sizeNotOver': 'ขนาดไฟล์ไม่เกิน',
        'finalApprovedDate': 'วันที่อนุมัติ',
        'main': {
            'other': 'อื่นๆ',
            'search': 'ค้นหา',
            'close': 'ปิด',
            'Advance Search': 'ค้นหาขั้นสูง',
            'Keyword': 'คำค้นหา',
            'Status': 'สถานะ',
            'All': 'ทั้งหมด',
            'Edit': 'แก้ไข',
            'Duplicate': 'คัดลอก',
            'Cancel': 'ยกเลิก',
            'View': 'แสดง',
            'Go to Approve': 'แสดงหน้าจออนุมัติ',
            'Delete': 'ลบ',
            'Search': 'ค้นหา',
            'ClearSearch': 'ล้างตัวกรอง',
            'Description': 'รายการ',
            'Name': 'ชื่อ',
            'Email': 'อีเมล',
            'Company': 'บริษัท',
            'Position': 'ตำแหน่ง',
            'Department': 'หน่วยงาน',
            'Add': 'เพิ่ม',
            'Update': 'ปรับปรุง',
            'Attachments': 'ไฟล์แนบ',
            'Attachment': 'ไฟล์แนบ',
            'ViewAttachment': 'ดูไฟล์แนบ',
            'Title': 'รายการ',
            'Pos': 'ลำดับ',
            'Move Up': 'ย้ายขึ้น',
            'Move Down': 'ย้ายลง',
            'Update Data': 'วันที่ปรับปรุง',
            'Created Date': 'วันที่สร้าง',
            'Last Updated': 'วันที่ปรับปรุงล่าสุด',
            'Date': 'วันที่',
            'Remark': 'หมายเหตุ',
            'Reviewed': 'ทบทวน',
            'Rejectd': 'ปฏิเสธ',
            'Not Response': 'ไม่ตอบสนอง',
            'Approved': 'อนุมัติ',
            'Print Form': 'พิมพ์',
            'e-JSA Search': 'ค้นหา',
            'Approve': 'อนุมัติ',
            'Review': 'รับรอง',
            'Reject': 'ปฏิเสธ',
            'Sign': 'Sign',
            'Sign Upload': 'อัพโหลดลายเซ็น',
            'Save': 'บันทึก',
            'Logout': 'ออกจากระบบ',
            'Created By': 'สร้างโดย',
            'Updated By': 'ปรับปรุงโดย',
            'General Information': 'ข้อมูลทั่วไป',
            'Job Information': 'ข้อมูลงาน',
            'Area Information': 'ข้อมูลพื้นที่',
            'EmployeeSearch': 'ค้นหาพนักงาน',
            'RemarkTitle' : 'หมายเหตุ?',
            'RemarkSubTitle' : 'โปรดระบุเหตุผล!',
            'createRevision': 'สร้างฉบับปรับปรุง',
            'updateDateFrom': 'วันที่ปรับปรุงตั้งแต่',
            'updateDateTo': 'วันที่ปรับปรุงถึง',
        },
        'Nav': {
            'Home': 'หน้าหลัก',
            'Ejsa': 'e-JSA',
            'MyTask': 'งานของฉัน',
            'TaskHistory': 'ข้อมูลของฉัน',
            'MasterData': 'ตั้งค่าข้อมูล',
            'Configure': 'ตั้งค่าระบบ',
            'SystemUser': 'ผู้ใช้งานระบบ',
            'CreateNew': 'สร้าง JSA',
            'CreateNewAttachment': 'สร้าง JSA Attachment',
            'EjsaData': 'ข้อมูล e-JSA',
            'Site': 'Site',
            'Area': 'Area Authority',
            'ActivityType': 'Activity Type',
            'HazardGroup': 'Hazard Group',
            'Hazard': 'Hazard',
            'TopEvent': 'Top Event',
            'Barriers': 'Barriers',
            'GeneralSafety': 'General Safety',
            'LifeSavingRule': 'LSR/PSR',
            'ContractCompany': 'Contractor Company Name',
            'EjsaSearch': 'ค้นหา',
        },
        'dashboard': {
            'menu1': 'สร้าง JSA',
            'menu2': 'งานของฉัน',
            'menu3': 'ข้อมูลของฉัน',
            'menu4': 'ค้นหา',
            'menu5': 'ผู้ใช้งานระบบ',
            'menu6': 'คู่มือการใช้งาน',
        },
        'ejsa': {
            'e-JSA Data': 'ข้อมูล e-JSA',
            'jobStatus': {
                'Draft': 'แบบร่าง',
                'Waiting for Contract Holder review': 'รอผู้ถือสัญญาทบทวน',
                'Waiting for review': 'รอการทบทวน',
                'Waiting for approval': 'รอการอนุมัติ',
                'Approved': 'อนุมัติ',
                'Canceled': 'ยกเลิก',
                'Expired': 'หมดอายุ',
                'Rejected': 'ปฏิเสธ',
                'Search': 'ค้นหา',
            },
            'Site': 'ไซต์ / โครงการ',
            'Status': 'สถานะ JSA',
            'Area Authority': 'เขตพื้นที่รับผิดชอบ',
            'JSA Perform by': 'ดำเนินกิจกรรมโดย',
            'Risk Assessment For': 'ประเมินความเสี่ยงสำหรับ',
            'Job': 'ตามลักษณะกิจกรรมของงาน',
            'Area': 'ตามลักษณะพื้นที่',
            'Type of Activity': 'ประเภทของกิจกรรม',
            'Job Condition': 'สภาพการทำงาน',
            'Routine': 'งานประจำ',
            'Non-routine': 'งานไม่ประจำ',
            'Non-Routine': 'งานไม่ประจำ',
            'Emergency': 'งานเร่งด่วนหรือกรณีเกิดเหตุฉุกเฉิน',
            'Area Classification': 'ประเภทของพื้นที่',
            'Hazardous Area': 'พื้นที่มีเชื้อเพลิงไวไฟ',
            'Non-hazardous Area': 'พื้นที่ปลอดเชื้อเพลิงไวไฟ',
            'Unclassified Area': 'ระบุไม่ได้',
            'Job Owner': 'เจ้าของงาน',
            'Area Owner': 'เจ้าของพื้นที่',
            'JSA Number': 'เลข JSA',
            'Job/Area Owner': 'เจ้าของ งาน/พื้นที่',
            'JSA for': 'JSA สำหรับ',
            'JSAName': 'ชื่อ JSA',
            'Staff': 'ปตท.สผ.',
            'Contractor': 'พนักงานผู้รับเหมา',
            'Contract Holder Review': 'ชื่อผู้ที่ถือสัญญา หรือผู้ที่ได้รับมอบหมายใน PTTEP',
            'Operation Authority Review': 'ผู้มีอำนาจด้านควบคุมพื้นที่',
            'Safe Authority Review': 'ผู้มีอำนาจด้านความปลอดภัย',
            'Approver': 'ผู้อนุมัติ',
            'Contract Company': 'บริษัทผู้รับเหมา',
            'e-JSA List': 'รายการ e-JSA',
            'My Task': 'งานของฉัน',
            'Task History': 'ข้อมูลของฉัน',
            'Create New e-JSA': 'สร้าง e-JSA',
            'Edit e-JSA': 'แก้ไข e-JSA',
            'Create New e-JSA Attachment': 'สร้าง e-JSA Attachment',
            'Contractor Company Name': 'ชื่อบริษัทผู้รับเหมา',
            'PTTEP Contract Holder or PTTEP Assigned Person': 'ชื่อผู้ที่ถือสัญญา หรือชื่อผู้ที่รับมอบหมายใน PTTEP',
            'Name of Job': 'ชื่อกิจกรรมที่ประเมินความเสี่ยง',
            'Name of Area': 'ชื่อพื้นที่ที่ประเมินความเสี่ยง',
            'No. working day': 'จำนวนวันที่ใช้ในการปฏิบัติงาน',
            'Complexity of job': 'ความซับซ้อนของงาน',
            'Complexity1': 'งานทั่วไป (ขั้นตอนการทำงานน้อยกว่า 10 ขั้นตอน) มีการตรวจสอบการทำงาน',
            'Complexity2': 'งานซับซ้อนที่มีการตรวจสอบการทำงานหรืองานทั่วไปที่ปราศจากการตรวจสอบ',
            'Complexity3': 'งานซับซ้อน (ขั้นตอนการทำงานมากกว่า 10 ขั้นตอน) ไม่มีการตรวจสอบการทำงาน',
            'Stress of job': 'ความเครียดของงาน',
            'Normal workload': 'ภาระงานปกติ',
            'Heavy workload': 'ภาระงานหนัก',
            'Overload work': 'ภาระงานที่มากเกินไป',
            'Work Location': 'สถานที่ดำเนินกิจกรรม',
            'Risk assessment team members': 'สมาชิกในทีมประเมินความเสี่ยง',
            'General Safety Title': 'ข้อควรระวังความปลอดภัยทั่วไป / ทรัพยากรที่จำเป็นสำหรับงาน',
            'Life Saving Rule Icon': 'กฎรักษ์ชีวิตและความปลอดภัย',
            'Add Step': 'เพิ่มขั้นตอนปฏิบัติงาน',
            'Work Step': 'ขั้นตอนการปฏิบัติงาน',
            'Work Activity': 'รายละเอียดงาน',
            'Potential Hazards': 'อันตรายที่อาจเกิดขึ้น',
            'Top Event': 'เหตุการณ์ไม่พึงประสงค์',
            'Initial Risk': 'ความเสี่ยงเบื้องต้น',
            'BarriersandMitigation': 'มาตรการควบคุมป้องกันและบรรเทาอันตราย',
            'Residual Risk': 'ความเสี่ยงที่เหลืออยู่',
            'Action Party': 'ผู้ดำเนินการ',
            'Create Activity': 'เพิ่มกิจกรรม',
            'Create Harzard': 'ระบุอันตราย',
            'Sub Area': 'พื้นที่ย่อย',
            'Add Sub Area': 'เพิ่มพื้นที่ย่อย',
            'Reviewer And Approver': 'ผู้ตรวจสอบและผู้อนุมัติ',
            'Contract Holder or Assigned Person Review': 'ผู้ที่ถือสัญญา หรือชื่อผู้ที่รับมอบหมายใน PTTEP',
            'Operating Authority Review': 'ผู้มีอำนาจด้านการควบคุมพื้นที่',
            'Safety Authority Review': 'ผู้มีอำนาจด้านความปลอดภัย',
            'Area Authority Approved': 'ผู้มีอำนาจสูงสุดในพื้นที่',
            'Save Draft': 'บันทึก',
            'Send Report': 'ส่งรายงาน',
            'Step': 'ขั้นตอนการปฏิบัติงาน',
            'AreaStep': 'พื้นที่ย่อย',
            'Pos': 'ลำดับ',
            'workingDayDesc': '• จำนวนวันทำงานทั้งหมด ตั้งแต่เริ่มต้นทำงานจนเสร็จสิ้น (งานไม่ประจำ/งานเร่งด่วนหรือกรณีเกิดเหตุฉุกเฉิน) \r\n • จำนวนวันทำงานทั้งหมดใน 1 ปี (งานประจำ)',
            'data71': 'data71',
            'data72': 'data72',
        }
        ,
        'hazard': {
            'Hazard Group': 'กลุ่มของอันตราย',
            'Others': 'อื่นๅ',
            'Hazard': 'รายละเอียดอันตราย',
            'Top Event': 'เหตุการณ์ไม่พึงประสงค์',
            'Consequence Before': 'ระดับความรุนแรง (เริ่มต้น)',
            'Barriers': 'มาตรการควบคุมป้องกัน',
            'Other Barriers': 'มาตรการควบคุมป้องกันอื่นๆ',
            'Name': 'รายละเอียดมาตรการควบคุมป้องกัน',
            'Barrier Type': 'ลำดับชั้นของการควบคุมป้องกันอันตราย',
            'Add': 'เพิ่ม',
            'Action Party': 'ผู้ดำเนินการ',
            'Consequence After': 'ระดับความรุนแรง (ที่เหลืออยู่)',
            'Probability After': 'โอกาสในการเกิดอันตราย (ที่เหลืออยู่)',
            'Rare': 'น้อยมาก',
            'Unlikely': 'น้อย',
            'Possible': 'ปานกลาง',
            'Likely': 'สูง',
            'Almost Certain': 'สูงมาก',
            'Safety Mitigation': 'มาตรการบรรเทาอันตราย',
            'ADD': 'เพิ่ม',
            'Update': 'ปรับปรุง',
            'Delete': 'ลบ',
            'Edit': 'แก้ไข',
            'Create Harzard': 'เพิ่มการระบุอันตรายและมาตรการป้องกัน',
            'Update Harzard': 'แก้ไขการระบุอันตรายและมาตรการป้องกัน',
            'incident': 'กิจกรรมที่เคยเกิดอุบัติเหตุมาก่อนตั้งแต่ MTC ขึ้นไป',
        },
        'workStep': {
            'addWorkStep': 'เพิ่มขั้นตอนปฏิบัติงาน',
            'updateWorkStep': 'แก้ไขขั้นตอนปฏิบัติงาน',
            'addAreaStep': 'เพิ่มพื้นที่ย่อย',
            'updateAreaStep': 'แก้ไขพื้นที่ย่อย',
            'update': 'ปรับปรุง',
            'add': 'เพิ่ม',
            'title': 'รายละเอียด',
        },
        'activity': {
            'addActivity': 'เพิ่มกิจกรรม',
            'updateActivity': 'แก้ไขกิจกรรม',
            'update': 'ปรับปรุง',
            'add': 'เพิ่ม',
            'title': 'รายละเอียด',
        },
        'popup': {
            'confirm': 'ยืนยัน ?',
            'success': 'สำเร็จ !',
            'dataSave': 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว!',
            'error': 'ผิดพลาด !',
            'ok': 'ยืนยัน',
            'cancel': 'ยกเลิก',
            'yes': 'ยืนยัน',
            'no': 'ยกเลิก',
            'noData': 'ไม่พบข้อมูล',
            'pleaseCompleteData': 'กรุณาใส่ข้อมูลให้ครบถ้วน!',
            'saveRecordAlert': 'ยืนยันการบันทึกข้อมูล ?',
            'submitRecordAlert': 'ยืนยันการส่งข้อมูล ?',
            'approveRecordAlert': 'ยืนยันการอนุมัติ ?',
            'reviewRecordAlert': 'ยืนยันการรับรอง ?',
            'rejectRecordAlert': 'ยืนยันการปฏิเสธ ?',
            'sendAlert': 'ยืนยันการส่งข้อมูล ?',
            'draftAlert': 'ยืนยันการบันทึกข้อมูล ?',
            'deleteAlert': 'ยืนยันการลบข้อมูล ?',
            'cancelAlert': 'ยืนยันการยกเลิกข้อมูล ?',
            'dataSubmited': 'บันทึกข้อมูลสำเร็จ !',
            'dataSaved': 'บันทึกข้อมูลสำเร็จ !',
            'dataApproved': 'ข้อมูลถูกอนุมัติเรียบร้อยแล้ว!',
            'dataReviewed': 'ข้อมูลถูกรับรองเรียบร้อยแล้ว!',
            'dataRejected': 'ข้อมูลถูกปฏิเสธเรียบร้อยแล้ว!',
            'dataSent': 'ส่งข้อมูลสำเร็จ!. เลข JSA. #',
            'drafted': 'ส่งข้อมูลสำเร็จ!. เลข JSA. #',
        },
        'ejsaAttachment': {
            'approvedJsa': 'JSA ที่อนุมัติเรียบร้อยแล้ว',
        },
        'revision':{
            'JobRevision': 'ฉบับปรับปรุง',
            'Creator': 'ผู้สร้าง',
            'Date': 'วันที่',
            'Revision': 'ฉบับปรับปรุง',
            'BackToCurrentRevision': 'กลับไปยังฉบับปัจจุบัน',
            'Current': 'ปัจจุบัน',
        }
    }
};
