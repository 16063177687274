import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { GlobalService } from './services/global.service';
import { IsTokenExpired, isExistData } from './utils/util';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _splashScreenService: FuseSplashScreenService,
    private _globalService: GlobalService
  ) {
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const refreshToken = sessionStorage.getItem('refresh_token') ?? '';
    const csrfToken = sessionStorage.getItem('csrf') ?? '';
    const accessToken = sessionStorage.getItem('token') ?? '';

    let newReq = req.clone();

    if (req.url.includes('refresh-token')) {
      newReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + refreshToken).set('ValidateAntiForgeryToken', csrfToken),
        withCredentials: true,
      });
      return this.returnInterceptedRequest(newReq, next);
    }
    else if (req.url.includes('.json')) {
      return this.returnInterceptedRequest(newReq, next);
    }
    else if (!isExistData(accessToken)) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('refresh_token');
      newReq = req.clone({
        headers: req.headers.set('ValidateAntiForgeryToken', csrfToken),
        withCredentials: true,
      });
      return this.returnInterceptedRequest(newReq, next);
    }
    else if (IsTokenExpired(accessToken)) {
      //refresh token and re get csrf token
      return this._globalService.signInUsingToken().pipe(
        switchMap(() => this._globalService.getCsrf()),
        switchMap(() => {
          const csrfToken = sessionStorage.getItem('csrf');
          const accessToken = sessionStorage.getItem('token');
          newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + accessToken).set('ValidateAntiForgeryToken', csrfToken ?? ''),
            withCredentials: true,
          });
          return this.returnInterceptedRequest(newReq, next);
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );

    }
    else {
      if (sessionStorage.getItem('csrf')) {
        newReq = req.clone({
          headers: req.headers.set('ValidateAntiForgeryToken', csrfToken)
        });
      }
      if (sessionStorage.getItem('token')) {
        newReq = newReq.clone({
          headers: newReq.headers.set('Authorization', 'Bearer ' + accessToken)
        });
      }
      return this.returnInterceptedRequest(newReq, next);
    }

  }




  private returnInterceptedRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        this._splashScreenService.hide();
        return this.processErrorCode(error);
      })
    );
  }

  private processErrorCode(error: any): Observable<any> {
    const bypassHandle = ['/auth/login', 'login'];

    if (error.url?.includes(bypassHandle)) {
      return throwError(() => error);
    }
    else if (error instanceof HttpErrorResponse && (error.status === 404)) {
      Swal.fire('', 'Content not found', 'error').then(() => { });
      return throwError(() => error);
    }
    else if (error instanceof HttpErrorResponse && (error.status === 400 || error.status === 422)) {
      const errorMessage = this.setErrorMessage(error);
      Swal.fire('', errorMessage, 'warning');
    }
    else if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
      Swal.fire('Error', 'Authentication Error', 'error').then(() => { });
    }
    else {
      Swal.fire('Error', 'API error Code ' + error.status, 'error');
    }
    return throwError(() => error);
  }

  private setErrorMessage(error: any): any {
    let errorMessage = '';
    if (error.error?.messageEN) {
      errorMessage = error.error?.messageEN;
    }
    return errorMessage;
  }
}
