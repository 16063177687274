import { UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AreaHazardModel } from 'app/models/area-hazard.model';
import { AreaStepModel } from 'app/models/area-step.model';
import { JobActivityModel } from 'app/models/job-activity.model';
import { JobStepModel } from 'app/models/job-step.model';
import jsPDF from 'jspdf';
import { Subject, takeUntil } from 'rxjs';

export const JobUtil = {
  IsAreaContainIncident: (areaHazards: AreaHazardModel[] | null): boolean => {
    if (!areaHazards) {
      return false;
    }
    let isContainIncident = false;
    areaHazards.forEach((item) => {
      if (item.IsIncident) {
        isContainIncident = true;
      }
    });
    return isContainIncident;
  },
  IsJobContainIncident: (jobActivity: JobActivityModel[] | null): boolean => {
    if (!jobActivity) {
      return false;
    }
    let isContainIncident = false;
    jobActivity.forEach((item) => {
      if (item.JobHazards.length > 0) {
        item.JobHazards.forEach((item1) => {
          if (item1.IsIncident) {
            isContainIncident = true;
          }
        });
      }
    });
    return isContainIncident;
  },

  IsJobCritical: (item: JobStepModel): boolean => {
    let isCritical = false;
    if (item.JobActivities.length > 0) {
      item.JobActivities.forEach((item1) => {
        if (item1.JobHazards.length > 0) {
          item1.JobHazards.forEach((item2) => {
            if (item2.ConsequenceAfter === 4 || item2.ConsequenceAfter === 5) {
              isCritical = true;
            }
          });
        }
      });
    }
    return isCritical;
  },
  IsAreaCritical: (item: AreaStepModel): boolean => {
    let isCritical = false;
    if (item.AreaHazards.length > 0) {
      item.AreaHazards.forEach((item2) => {
        if (item2.ConsequenceAfter === 4 || item2.ConsequenceAfter === 5) {
          isCritical = true;
        }
      });
    }
    return isCritical;
  },
  JobStatusText: (item: number): string => {
    if (item === 0) { return 'Draft'; }
    if (item === 1) { return 'Waiting for Contract Holder review'; }
    if (item === 2) { return 'Waiting for review'; }
    if (item === 3) { return 'Waiting for approval'; }
    if (item === 4) { return 'Approved'; }
    if (item === 5) { return 'Canceled'; }
    if (item === 6) { return 'Expired'; }
    if (item === 7) { return 'Rejected'; }
    return '';
  }
};

export const CompareStep = (a, b): number => {
  const itemA = a.Pos;
  const itemB = b.Pos;
  let comparison = 0;
  if (itemA > itemB) {
    comparison = 1;
  } else if (itemA < itemB) {
    comparison = -1;
  }
  return comparison;
};

export const MatchWordInArray = (array: string[] | null, word: string): boolean => {
  if (!array) {
    return false;
  }
  let isMatch = false;
  array.forEach((item) => {
    if (item.toLowerCase().includes(word.toLowerCase())) {
      isMatch = true;
    }
  });
  return isMatch;
};

export const NullIfNotValue = (value: any): any => value || null;
export const DefaultIfNotValue = (value: any, defaultValue: any): any => value && value != '' ? value : defaultValue;
export const EmptyIfNotValue = (value: any): any => value || '';
export const EmptyArrayNotValue = (value: any): any => value || [];
export const ReturnIfNumber = (value: any): any => {
  if (typeof (value) === 'number') {
    return value;
  }
  return null;
};
export const DefaultIfNumber = (value: any, defaultValue: number): any => {
  if (typeof (value) === 'number') {
    return value;
  }
  return defaultValue;
};
export const ReturnIfBoolean = (value: any): any => {
  if (typeof (value) === 'boolean') {
    return value;
  }
  return null;
};
export const ReturnFalseIfBoolean = (value: any): boolean => {
  if (typeof (value) === 'boolean') {
    return value;
  }
  return false;
};

export const ReturnTrueIfBoolean = (value: any): boolean => {
  if (typeof (value) === 'boolean') {
    return value;
  }
  return true;
};

export const JsonParseArray = (value: any): any => {
  try {
    return JSON.parse(value);
  }
  catch {
    return [];
  }
};

export const BlankOjectIsNull = (ModelClass: any, data: any): any => (data && typeof data === 'object') ? new ModelClass(data) : new ModelClass({});
export const BlankArrayIsNull = (ModelClass: any, dataArray: any): any => (Array.isArray(dataArray) ? dataArray.map(data => new ModelClass(data)) : []);
export const SefeJsonParse = (value: any): any => {
  try {
    return JSON.parse(value);
  }
  catch {
    return null;
  }
}

export const SefeJsonParseWithDefault = (value: any, defaultData: any): any => {
  try {
    return JSON.parse(value);
  }
  catch {
    return defaultData;
  }
}

export const SefeStringifyWithEmptyArray = (value: any): string => {
  if (!value || typeof (value) !== 'object') {
    return '[]';
  }
  return JSON.stringify(value);
}

export const Check3ConditionTrue = (condition1: boolean, condition2: boolean, condition3: boolean): boolean => {
  return condition1 && condition2 && condition3;
}

export const Check4ConditionTrue = (condition1: boolean, condition2: boolean, condition3: boolean, condition4: boolean): boolean => {
  return condition1 && condition2 && condition3 && condition4;
}

export const CheckAllArrayItemIsTrue = (array: boolean[]): boolean => {
  let isAllTrue = true;
  array.forEach((item) => {
    if (!item) {
      isAllTrue = false;
    }
  });
  return isAllTrue;
}

export const CheckSomeArrayItemIsTrue = (array: boolean[]): boolean => {
  let isSomeTrue = false;
  array.forEach((item) => {
    if (item) {
      isSomeTrue = true;
    }
  });
  return isSomeTrue;
}

export const ThaiText = (doc: jsPDF, str: any, x: number, y: number, option?: any): any => {
  str = str ?? '';
  const sara = ['่', '้', '๊', '๋', '์'];
  const pushers = ['ิ', 'ี', 'ึ', 'ื', 'ำ', 'ั'];
  let base = '';
  const dim = doc.getTextDimensions(str);

  const processCharacter = (c: string, i: number): void => {
    if (sara.indexOf(c) >= 0) {
      handleSaraCharacter(c, i);
    } else {
      base += c;
    }
  }

  const handleSaraCharacter = (c: string, i: number): void => {
    const pusher = base.charAt(base.length - 1);
    if (pushers.indexOf(pusher) >= 0) {
      handlePusherCharacter(c, i);
    } else if (str.charAt(i + 1) === 'ำ') { // next char is ำ
      const len = doc.getTextWidth(base + 'ำ');
      doc.text(c, x + len, y - (dim.h / 4));
    } else {
      base += c;
    }
  }

  const handlePusherCharacter = (c: string, i: number): void => {
    const len = doc.getTextWidth(base);
    if (option?.align === 'center') {
      const remainText = str.substr(i + 1, base.length - 1);
      const len2 = doc.getTextWidth(remainText);
      doc.text(c, x + ((len / 2) - (len2 / 2)), y - (dim.h / 4));
    } else if (option?.align === 'right') {
      const remainText = str.substr(i + 1, base.length - 1);
      const len2 = doc.getTextWidth(remainText);
      doc.text(c, x - (len2), y - (dim.h / 4));
    } else {
      doc.text(c, x + len, y - (dim.h / 4));
    }
  }

  for (let i = 0; i < str.length; i++) {
    processCharacter(str.charAt(i), i);
  }

  doc.text(base, x, y, option ?? {});
}

export const isExistData = (data: any): boolean => {
  if (data === null || data === undefined || data === '' || data === 'undefined' || data === 'null') {
    return false;
  }
  return true;
}

//check token expired
export const IsTokenExpired = (token: string, offsetSeconds?: number): boolean => {
  // Return if there is no token
  if (!token || token === '') {
    return true;
  }

  // Get the expiration date
  const date = GetTokenExpirationDate(token);

  offsetSeconds = offsetSeconds ?? 0;

  if (date === null) {
    return true;
  }

  // Check if the token is expired
  return (date.valueOf() <= new Date().valueOf() + offsetSeconds * 1000);
}

export const GetTokenExpirationDate = (token: string): Date | null => {
  // Get the decoded token
  const decodedToken = DecodeToken(token);

  if (!Object.hasOwn(decodedToken, 'exp')) {
    return null;
  }

  // Convert the expiration date
  const date = new Date(0);
  date.setUTCSeconds(decodedToken.exp);

  return date;
}

export const DecodeToken = (token: string): any => {
  // Return if there is no token
  if (!token) {
    return null;
  }

  // Split the token
  const parts = token.split('.');

  if (parts.length !== 3) {
    throw new Error('The inspected token doesn\'t appear to be a JWT. Check to make sure it has three parts and see https://jwt.io for more.');
  }

  // Decode the token using the Base64 decoder
  const decoded = UrlBase64Decode(parts[1]);

  if (!decoded) {
    throw new Error('Cannot decode the token.');
  }

  return JSON.parse(decoded);
}

export const UrlBase64Decode = (str: string): string => {
  let output = str.replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4) {
    case 0:
      {
        break;
      }
    case 2:
      {
        output += '==';
        break;
      }
    case 3:
      {
        output += '=';
        break;
      }
    default:
      {
        throw Error('Illegal base64url string!');
      }
  }
  return B64DecodeUnicode(output);
}

export const B64DecodeUnicode = (str: any): string => {
  return decodeURIComponent(
    Array.prototype.map
      .call(B64decode(str), (c: any) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );
}

export const B64decode = (str: string): string => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  let output = '';

  str = str.replace(/={0,2}$/, '');

  if (str.length % 4 === 1) {
    throw new Error('\'atob\' failed: The string to be decoded is not correctly encoded.');
  }

  let buffer = 0;
  let bufferCount = 0;

  for (let idx = 0; idx < str.length; idx++) {
    const char = str.charAt(idx);
    const charIndex = chars.indexOf(char);

    if (charIndex !== -1) {
      buffer = (buffer << 6) | charIndex;
      bufferCount++;

      if (bufferCount === 4) {
        output += String.fromCharCode((buffer >> 16) & 255);
        output += String.fromCharCode((buffer >> 8) & 255);
        output += String.fromCharCode(buffer & 255);
        buffer = 0;
        bufferCount = 0;
      }
    }
  }

  if (bufferCount === 3) {
    output += String.fromCharCode((buffer >> 10) & 255);
    output += String.fromCharCode((buffer >> 2) & 255);
  } else if (bufferCount === 2) {
    output += String.fromCharCode((buffer >> 4) & 255);
  }

  return output;
}

export const RegisterEventEnableAndRequire = (dataForm:UntypedFormGroup, unsubscribeEvent:Subject<any>  , eventForm: string, controlForms: string[]): void => {
  dataForm.get(eventForm)?.valueChanges.pipe(takeUntil(unsubscribeEvent)).subscribe((value) => {
    if (value) {
      controlForms.forEach((controlForm) => {
        dataForm.get(controlForm)?.enable();
        dataForm.get(controlForm)?.setValidators([Validators.required]);
        dataForm.get(controlForm)?.updateValueAndValidity();
      });
    } else {
      controlForms.forEach((controlForm) => {
        dataForm.get(controlForm)?.disable();
        dataForm.get(controlForm)?.removeValidators([Validators.required]);
        dataForm.get(controlForm)?.updateValueAndValidity();
      });
    }
  });
}

export const RegisterEventEnable = (dataForm:UntypedFormGroup, unsubscribeEvent:Subject<any>  , eventForm: string, controlForms: string[]): void => {
  dataForm.get(eventForm)?.valueChanges.pipe(takeUntil(unsubscribeEvent)).subscribe((value) => {
    if (value) {
      controlForms.forEach((controlForm) => {
        dataForm.get(controlForm)?.enable();
        dataForm.get(controlForm)?.updateValueAndValidity();
      });
    } else {
      controlForms.forEach((controlForm) => {
        dataForm.get(controlForm)?.disable();
        dataForm.get(controlForm)?.updateValueAndValidity();
      });
    }
  });
}

export const RegisterEventRequire = (dataForm:UntypedFormGroup, unsubscribeEvent:Subject<any>  , eventForm: string, controlForms: string[]): void => {
  dataForm.get(eventForm)?.valueChanges.pipe(takeUntil(unsubscribeEvent)).subscribe((value) => {
    if (value) {
      controlForms.forEach((controlForm) => {
        dataForm.get(controlForm)?.setValidators([Validators.required]);
        dataForm.get(controlForm)?.updateValueAndValidity();
      });
    } else {
      controlForms.forEach((controlForm) => {
        dataForm.get(controlForm)?.removeValidators([Validators.required]);
        dataForm.get(controlForm)?.updateValueAndValidity();
      });
    }
  });
}

export const DuplicateArrayWithoutNull = (array: any[]): boolean=> {
  let isDuplicate = false;
  const newArray = array.filter(item => item !== null);
  if (newArray.length !== new Set(newArray).size) {
    isDuplicate = true;
  }
  return isDuplicate;
}

export const GetFileNameFormUrl = (url: string): string | null => {
  if(!url) return null;
  return url.substring(url.lastIndexOf('/') + 1);
}

export const GetFormGroupError = (formGroup: UntypedFormGroup): void => {
  const errors = {};
  Object.keys(formGroup.controls).forEach((key) => {
    const controlErrors: ValidationErrors = formGroup.get(key)?.errors as ValidationErrors;
    if (controlErrors != null) {
      Object.keys(controlErrors).forEach((keyError) => {
        errors[key] = controlErrors[keyError] + ' ' + keyError;
      });
    }
  });
};

export const GetDimensions = (url: string, fixedHeight: number): Promise<{ width: number, height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = (): void => {
      const aspectRatio = img.width / img.height;
      const width = fixedHeight * aspectRatio;
      resolve({ width, height: fixedHeight });
    };
    img.onerror = reject;
    img.src = url;
  });
}
