export const environment = {
    production: false,
    hmr: false,
    apiUrl: '/api',
    imageUrl: '/uploads/images',
    fullUrl: 'http://localhost:60806',
    redirectUrl: 'http://localhost:4200',
    uploadUrl: '/service/uploadimage',
    clientId: 'e555e7ba-dd19-4c12-a709-1e7e20dd5ca7',
    authority: 'https://login.microsoftonline.com/0021a565-451d-45cd-bcb9-48166d9302b6',
    b2cClientId: 'ce204a84-2844-44d2-8219-64a20685e9fd',
    b2cAuthority: 'https://login.microsoftonline.com/7e6e63e1-3382-492f-92f2-bbaa8773c39f',
    dateFormat: {
        parse: {
            dateInput: 'YYYY-MM-DD',
        },
        display: {
            dateInput: 'YYYY-MM-DD',
            monthYearLabel: 'MMM YYYY',
            dateA11yLabel: 'LL',
            monthYearA11yLabel: 'MMMM YYYY',
        },
    }
};
