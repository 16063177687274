export class FusePerfectScrollbarGeometry
{
    public x: number;
    public y: number;

    public w: number;
    public h: number;

    constructor(x: number, y: number, w: number, h: number)
    {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
    }
}

export class FusePerfectScrollbarPosition
{
    public x: FuseScrollPosition;
    public y: FuseScrollPosition;

    constructor(x: FuseScrollPosition, y: FuseScrollPosition)
    {
        this.x = x;
        this.y = y;
    }
}

export type FuseScrollPosition = number | 'start' | 'end';
