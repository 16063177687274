/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  
  private _env: any = {};
  constructor(private _httpClient: HttpClient) { }

  get(): Observable<any> {
    if (!this._env?.api) {
      return this._httpClient.get('env/env/env.json').pipe(
        tap((resp: any) => {
          this._env = resp;
        })
      );
    } else {
      return of(true);
    }
  }

  getApi(): string {
    return this._env.api;
  }

  getAzureConfig(): any {
    return this._env.azure;
  }

  getConfig(): any {
    return this._env;
  }


  isData(): boolean {
    if (!this._env?.api) {
      return false;
    } else {
      return true;
    }
  }


}
