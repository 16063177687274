import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { FuseProgressBarModule } from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ConnectionService } from '@oot/connection/connection.service';
import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
import { AuthGuardService } from 'app/services/auth-guard.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AppResolver } from 'src/app.resolvers';
import { GlobalService } from './services/global.service';
import { SearchService } from './services/search.service';
import { AuthInterceptor } from './auth.interceptor';
import { EnvironmentService } from './services/env.service';
import { ErrorHandlerService } from './services/error.service';
import { MyMonitoringService } from './services/logging.service';
import { QuillModule } from 'ngx-quill';

const appRoutes: Routes = [

    {
        path: '',
        resolve: [AppResolver],
        children: [
            {
                path: 'logon',
                loadChildren: () => import('app/main/logon/logon.module').then(m => m.LogonModule)
            },
            {
                path: 'login',
                loadChildren: () => import('app/main/logon/logon.module').then(m => m.LogonModule)
            },
            {
                path: 'login',
                loadChildren: () => import('app/main/logon/logon.module').then(m => m.LogonModule)
            },
            {
                path: 'dashboard',
                loadChildren: () => import('app/main/dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [AuthGuardService]
            },
            {
                path: 'ejsa',
                loadChildren: () => import('app/main/ejsa/ejsa.module').then(m => m.EjsaModule),
                canActivate: [AuthGuardService]
            },
            {
                path: 'search',
                loadChildren: () => import('app/main/ejsa/ejsa-search/ejsa-search.module').then(m => m.EjsaSearchModule),
                canActivate: [AuthGuardService]
            },
            {
                path: 'my-task',
                loadChildren: () => import('app/main/ejsa/my-task/my-task.module').then(m => m.MyTaskModule),
                canActivate: [AuthGuardService]
            },
            {
                path: 'task-history',
                loadChildren: () => import('app/main/ejsa/task-history/task-history.module').then(m => m.TaskHistoryModule),
                canActivate: [AuthGuardService]
            },
            {
                path: 'master',
                loadChildren: () => import('app/main/master/master.module').then(m => m.MasterModule),
                canActivate: [AuthGuardService]
            },
            {
                path: 'user',
                loadChildren: () => import('app/main/user/user.module').then(m => m.UserModule),
                canActivate: [AuthGuardService]
            },
            {
                path: 'configure',
                loadChildren: () => import('app/main/configure/configure.module').then(m => m.ConfigureModule),
                canActivate: [AuthGuardService]
            },
            {
                path: 'pdf',
                loadChildren: () => import('app/main/pdf/pdf.module').then(m => m.PdfModule),
                canActivate: [AuthGuardService]
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'logon'
            },
            {
                path: '**',
                redirectTo: 'logon'
            }
        ]
    }
];


@NgModule({
    declarations: [
        AppComponent
    ],
    bootstrap: [
        AppComponent
    ], imports: [BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),
        // Material moment date module
        //  MatMomentDateModule,
        // Material
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        NgxMatSelectSearchModule,
        //  FuseSidebarModule,
        //  FuseThemeOptionsModule,
        // App modules
        QuillModule.forRoot({
            modules: {
                toolbar: {
                    container: [
                        ['bold', 'italic', 'underline'],
                        [{ 'list': 'bullet' }],
                        [{ 'size': ['small', false, 'large', 'huge'] }],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'font': ['sansserif', 'serif', 'monospace', 'arial', 'tahoma'] }],
                        [{ 'align': [] }],
                        ['clean'],
                        ['link'],
                        ['code-block']
                    ]
                }
            },
            customOptions: [
                {
                    import: 'formats/font',
                    whitelist: ['mirza', 'roboto', 'aref', 'serif', 'sansserif', 'monospace', 'arial', 'tahoma']
                }
            ]
        }),
        LayoutModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: 'dummy',
                authority: 'dummy',
            },
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: false,
            },
            system: {
                loggerOptions: {
                    piiLoggingEnabled: false
                }
            }
        }), {
            interactionType: InteractionType.Redirect
        }, {
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map()
        })], providers: [
            ConnectionService,
            GlobalService,
            EnvironmentService,
            SearchService,
            MyMonitoringService,
            AuthGuardService,
            {
                provide: HTTP_INTERCEPTORS,
                useClass: AuthInterceptor,
                multi: true
            },
            { provide: ErrorHandler, useClass: ErrorHandlerService },
            provideHttpClient(withInterceptorsFromDi())
        ]
})
export class AppModule {
}
