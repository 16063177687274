import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { AuthenticationService } from 'app/services/autentication.service';
import { GlobalService } from 'app/services/global.service';

@NgModule({
    declarations: [
        ToolbarComponent
    ],
    providers: [
        GlobalService,
        AuthenticationService
    ],
    imports: [
        TranslateModule.forChild(),
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        FuseSharedModule
    ],
    exports: [
        ToolbarComponent
    ]
})
export class ToolbarModule {
}
