import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'fuse-nav-horizontal-item',
    templateUrl: './item.component.html'
})
export class FuseNavHorizontalItemComponent {
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: any;

}
