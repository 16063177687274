import { Injectable } from '@angular/core';
import { EnvironmentService } from 'app/services/env.service';
import { GlobalService } from 'app/services/global.service';
import { forkJoin, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppResolver {
  constructor(private _envService: EnvironmentService, private _globalService: GlobalService) {
  }
  resolve(): Observable<any> {
    return forkJoin([
      this._envService.get(),
      this._globalService.getCsrf()
    ]);
  }
}
