import { Component, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'data-not-found',
  templateUrl: './data-not-found.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})

export class DataNoFoundComponent  {
}
