import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'app/material.module';
import { DataNoFoundComponent } from './data-not-found.component';

@NgModule({
  imports: [
    MaterialModule,
    TranslateModule.forChild(),
  ],
  providers: [
  ],
  exports: [
    DataNoFoundComponent
  ],
  declarations: [
    DataNoFoundComponent
  ]
})
export class DataNotFoundModule { }
