import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { EnvironmentService } from 'app/services/env.service';
import { Observable } from 'rxjs';

@Injectable()
export class ConnectionService {
    constructor(private http: HttpClient, private fuseSplashScreen: FuseSplashScreenService,
        private _envService: EnvironmentService
    ) {
        
    }

    
    post(url: string, data: any, splash: boolean = true): Promise<any> {

        
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache'
            })
        };

        return new Promise((resolve, reject) => {
            if (splash) {
                this.fuseSplashScreen.show();
            }
            this.http.post(`${this._envService.getApi()}${url}`, data, httpOptions).subscribe({
                next: (response: any) => {
                    this.fuseSplashScreen.hide();
                    resolve(response);
                },
                error: (error) => {
                    if (splash) {
                        this.fuseSplashScreen.hide();
                    }
                    reject(new Error(error));
                }
            });
        });
    }


    postOf(url: string, data: any): Observable<any> {
        return this.http.post(`${this._envService.getApi()}${url}`, data);
    }

    getOf(url: string): Observable<any> {
        return this.http.get(`${this._envService.getApi()}${url}`);
    }


    requestCommand(url: string, data: any, splash: boolean = true): Promise<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache'
            })
        };
        if (data === null) { data = {}; }
        return new Promise((resolve, reject) => {
            if (splash) {
                this.fuseSplashScreen.show();
            }
            this.http.post(`${this._envService.getApi()}${url}`, data, httpOptions).subscribe({
                next: (response: any) => {
                    this.fuseSplashScreen.hide();
                    resolve(response);
                },
                error: (error) => {
                    if (splash) {
                        this.fuseSplashScreen.hide();
                    }
                    reject(new Error(error));
                }
            });

        });
    }

    loginPost(url: string, data: any, splash: boolean = true): Promise<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache'
            })
        };

        const body = Object.keys(data).map(key => key + '=' + encodeURIComponent(data[key])).join('&');

        return new Promise((resolve, reject) => {
            if (splash) {
                this.fuseSplashScreen.show();
            }
            this.http.post(`${this._envService.getApi()}` + url, body, httpOptions).subscribe({
                next: (response: any) => {
                    this.fuseSplashScreen.hide();
                    resolve(response);
                },
                error: (error) => {
                    if (splash) {
                        this.fuseSplashScreen.hide();
                    }
                    reject(new Error(error));
                }
            });
        });
    }

    get(url: string, splash: boolean = true): Promise<any> {
        const token = sessionStorage.getItem('token');
        const byPassApiUrl = ['env/env/env.json'];
        if (byPassApiUrl.includes(url)) {
            return new Promise((resolve, reject) => {
                this.http.get(url).subscribe({
                    next: (response: any) => {
                        resolve(response);
                    },
                    error: (error) => {
                        reject(new Error(error));
                    }
                });
            });
        }
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'token': (token ?? '')
            })
        };
        return new Promise((resolve, reject) => {
            if (splash) {
                this.fuseSplashScreen.show();
            }
            this.http.get(`${this._envService.getApi()}${url}`, httpOptions).subscribe({
                next: (response: any) => {
                    this.fuseSplashScreen.hide();
                    resolve(response);
                },
                error: (error) => {
                    reject(new Error(error));
                }
            });

        });
    }


}
