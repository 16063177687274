import { NullIfNotValue, ReturnIfBoolean, ReturnIfNumber } from 'app/utils/util';
export class UserModel {
    Id: number;
    AzureId: string;
    LocationId: number[];
    SiteId: number[];
    AdminSiteId: number;
    ContractCompanyId: number;
    Role: string;
    Code: string;
    AdminType: number;
    Username: string;
    Password: string;
    LocationTH: string;
    LocationEN: string;
    SiteTH: string;
    SiteEN: string;
    Name: string;
    Sign: string;
    Email: string;
    Position: string;
    Location: string;
    Section: string;
    Division: string;
    Department: string;
    Status: boolean;
    IsDelete: string;
    CreatedDate: string;
    CreatedUser: string;
    CreatedIP: string;
    UpdatedDate: string;
    UpdatedUser: string;
    UpdatedIP: string;
    LastLogin: string;
    LastLoginIP: string;

    constructor(data) {
        this.Id = ReturnIfNumber(data.Id);
        this.AzureId = NullIfNotValue(data.AzureId);
        this.SiteId = ReturnIfNumber(data.SiteId);
        this.ContractCompanyId = ReturnIfNumber(data.ContractCompanyId);
        this.AdminSiteId = ReturnIfNumber(data.AdminSiteId);
        this.LocationTH = NullIfNotValue(data.LocationTH);
        this.LocationEN = NullIfNotValue(data.LocationEN);
        this.SiteTH = NullIfNotValue(data.SiteTH);
        this.SiteEN = NullIfNotValue(data.SiteEN);
        this.Sign = NullIfNotValue(data.Sign);
        this.LocationId = ReturnIfNumber(data.LocationId);
        this.Role = NullIfNotValue(data.Role);
        this.Code = NullIfNotValue(data.Code);
        this.AdminType = ReturnIfNumber(data.AdminType);
        this.Username = NullIfNotValue(data.Username);
        this.Password = NullIfNotValue(data.Password);
        this.Name = NullIfNotValue(data.Name);
        this.Email = NullIfNotValue(data.Email);
        this.Position = NullIfNotValue(data.Position);
        this.Location = NullIfNotValue(data.Location);
        this.Section = NullIfNotValue(data.Section);
        this.Division = NullIfNotValue(data.Division);
        this.Department = NullIfNotValue(data.Department);
        this.Status = ReturnIfBoolean(data.Status);
        this.IsDelete = ReturnIfBoolean(data.IsDelete);
        this.CreatedDate = NullIfNotValue(data.CreatedDate);
        this.CreatedUser = NullIfNotValue(data.CreatedUser);
        this.CreatedIP = NullIfNotValue(data.CreatedIP);
        this.UpdatedDate = NullIfNotValue(data.UpdatedDate);
        this.UpdatedUser = NullIfNotValue(data.UpdatedUser);
        this.UpdatedIP = NullIfNotValue(data.UpdatedIP);
        this.LastLogin = NullIfNotValue(data.LastLogin);
        this.LastLoginIP = NullIfNotValue(data.LastLoginIP);
        if (data.LocationId) {
            try {
                this.LocationId = JSON.parse(data.LocationId);
            } catch (err) {
                this.LocationId = [];
            }
        }

        if (data.SiteId) {
            try {
                this.SiteId = JSON.parse(data.SiteId);
            } catch (err) {
                this.SiteId = [];
            }
        }
    }
}
