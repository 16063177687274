import { Injectable } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionService } from '@oot/connection/connection.service';
import { UserModel } from 'app/models/user.model';
import { BehaviorSubject, Observable, catchError, of, switchMap, take } from 'rxjs';

@Injectable()
export class GlobalService {

    user: UserModel | null;
    token: string | null;
    totalTask: number;
    currentLang: string;
    homeContent: string;
    onTokenChange: BehaviorSubject<string | null>  = new BehaviorSubject<string>('');
    onTotalTaskChange: BehaviorSubject<string | null>  = new BehaviorSubject<string>('');
    onUserChange: BehaviorSubject<UserModel | null> = new BehaviorSubject<UserModel | null>(null);
    onLanguageChange: BehaviorSubject<string | null> = new BehaviorSubject<string>('');
    firstRequest: boolean = true;
    constructor( 
        private _connectionService: ConnectionService,
        private _fuseNavigationService: FuseNavigationService,
        private _translateService: TranslateService,

    ) {

        this.totalTask = 0;
        this.token = '';
        this.user = new UserModel({});
        this.currentLang = this._translateService.currentLang;
        this.onLanguageChange = new BehaviorSubject<string>(this.currentLang);
        this.onUserChange = new BehaviorSubject<UserModel>(new UserModel({}));
        this._translateService.onLangChange.subscribe(() => {
            this.currentLang = this._translateService.currentLang;
            this.onLanguageChange?.next(this.currentLang);
        });
       this.tokenChangeEvent();
       this.totalTaskChangeEvent();
    }


    totalTaskChangeEvent(): void{

        this.onTotalTaskChange.subscribe((result) => {
            this._fuseNavigationService.updateNavigationItem('mytask', {
                badge: {
                    title: result
                }
            });
        });

    }

    tokenChangeEvent(): void{
        this.onTokenChange.subscribe((result) => {
            this.token = result;
            if (!this.token) {
                this.user = new UserModel({});
                this.onUserChange.next(null);
                return;
            }
            this.getProfile().then((result2) => {
                if (result2) {
                    this.getCsrf().pipe(take(1)).subscribe({});
                    this.user = new UserModel(result2);
                    this.getTotalTask();
                    this.onUserChange.next(new UserModel(result2));
                }
            });
        });
    }

    getCsrf(): Observable<any> {
        return  this._connectionService.getOf('/auth/csrf').pipe(
            switchMap((response: any) => {
                if (response) {
                    sessionStorage.setItem('csrf', response);
                }
                if(this.firstRequest){
                    this.firstRequest = false;
                    const token = sessionStorage.getItem('token');
                    if(token){
                        this.onTokenChange.next(token);
                    }

                }
                return of(response);
            })
        )
    }


    public signInUsingToken(): Observable<any> {

        
        return this._connectionService.getOf('/auth/refresh-token').pipe(
          catchError(() =>
            of(false),
          ),
          switchMap((res: any) => {
            if (!res.Token || !res.RefreshToken) {
              throw new Error('Invalid token');
            }
            sessionStorage.setItem('token', res.Token);
            sessionStorage.setItem('refresh_token', res.RefreshToken);
            return of(res);
          }),
        );
    
      }
    

    getProfile(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.token) {
                resolve(null);
            }
            this._connectionService.post('/user/getProfile', null).then((response: any) => {
                if (!response) {
                    resolve(null);
                }
                
                this._fuseNavigationService.updateNavigationItem('ejsa-list', { hidden: false });
                this._fuseNavigationService.updateNavigationItem('users', { hidden: true });
                this._fuseNavigationService.updateNavigationItem('master-data', { hidden: true });
                this._fuseNavigationService.updateNavigationItem('ejsasearch', { hidden: true });
                this._fuseNavigationService.updateNavigationItem('configure', { hidden: true });
                this._fuseNavigationService.updateNavigationItem('site', { hidden: true });
                this._fuseNavigationService.updateNavigationItem('activityType', { hidden: true });
                this._fuseNavigationService.updateNavigationItem('hazard', { hidden: true });
                this._fuseNavigationService.updateNavigationItem('general-safety', { hidden: true });
                this._fuseNavigationService.updateNavigationItem('life-saving-rule', { hidden: true });
                this._fuseNavigationService.updateNavigationItem('contract-company', { hidden: true });
                this._fuseNavigationService.updateNavigationItem('barrier', { hidden: true });

                if (response.Role == 'Admin' || response.Role == 'SiteAdmin') {
                    this._fuseNavigationService.updateNavigationItem('master-data', { hidden: false });
                }
                if (response.AdminType !== 2 || response.Role == 'Admin') {
                    this._fuseNavigationService.updateNavigationItem('ejsasearch', { hidden: false });
                }
                if (response.Role == 'Admin') {
                    this._fuseNavigationService.updateNavigationItem('configure', { hidden: false });
                    this._fuseNavigationService.updateNavigationItem('site', { hidden: false });
                    this._fuseNavigationService.updateNavigationItem('activityType', { hidden: false });
                    this._fuseNavigationService.updateNavigationItem('hazard', { hidden: false });
                    this._fuseNavigationService.updateNavigationItem('general-safety', { hidden: false });
                    this._fuseNavigationService.updateNavigationItem('life-saving-rule', { hidden: false });
                    this._fuseNavigationService.updateNavigationItem('contract-company', { hidden: false });
                    this._fuseNavigationService.updateNavigationItem('barrier', { hidden: false });
                    this._fuseNavigationService.updateNavigationItem('users', { hidden: false });
                }
                resolve(new UserModel(response));
            }).catch((error) => { reject(new Error(error)); });

        });
    }
    getHomeContent(): Promise<string> {
        return new Promise((resolve, reject) => {
            this._connectionService.post('/config/GetHomeContent', null).then((response: any) => {
                this.homeContent = response;
                resolve(this.homeContent);
            }).catch((error) => { reject(new Error(error)); });
        });
    }
    getTotalTask(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._connectionService.post('/job/getTotalTask', null).then((response: any) => {
                if (typeof (response) == 'number') {
                    this.totalTask = response;
                }
                else {
                    this.totalTask = 0;
                }
                this.onTotalTaskChange.next(this.totalTask.toString());
                resolve(this.totalTask);

            }).catch((error) => { reject(new Error(error)); });
        });
    }

}
