export const locale = {
    lang: 'en',
    data: {
        'View e-JSA':'View e-JSA',
        'View e-JSA Revision':'View e-JSA Revision',
        'dataNotFound': 'No data found',
        'select': 'Select',
        'sizeNotOver': 'Maximum file size',
        'finalApprovedDate': 'Final Approved Date',
        'main': {
            'other': 'Other',
            'search': 'Search',
            'close': 'Close',
            'Advance Search': 'Advance Search',
            'Keyword': 'Keyword',
            'Status': 'Status',
            'All': 'All',
            'Edit': 'Edit',
            'Duplicate': 'Duplicate',
            'Cancel': 'Cancel',
            'View': 'View',
            'Go to Approve': 'Go to Approve',
            'Delete': 'Delete',
            'Search': 'Search',
            'ClearSearch': 'Clear',
            'Description': 'Description',
            'Name': 'Name',
            'Email': 'Email',
            'Company': 'Company',
            'Position': 'Position',
            'Department': 'Department',
            'Add': 'Add',
            'Update': 'Update',
            'Attachments': 'Attachments',
            'Attachment': 'Attachment',
            'ViewAttachment': 'View  Attachment',
            'Title': 'Title',
            'Pos': 'Pos',
            'Move Up': 'Move Up',
            'Move Down': 'Move Down',
            'Update Data': 'Update Data',
            'Created Date': 'Created Date',
            'Last Updated': 'Last Updated',
            'Date': 'Date',
            'Remark': 'Remark',
            'Reviewed': 'Reviewed',
            'Rejectd': 'Rejected',
            'Not Response': 'Not Response',
            'Approved': 'Approved',
            'Print Form': 'Print Form',
            'e-JSA Search': 'Search',
            'Approve': 'Approve',
            'Review': 'Review',
            'Reject': 'Reject',
            'Sign': 'Sign',
            'Sign Upload': 'Upload Signature',
            'Save': 'Save',
            'Logout': 'Logout',
            'Created By': 'Created By',
            'Updated By': 'Updated By',
            'General Information': 'General Information',
            'Job Information': 'Job Information',
            'Area Information': 'Area Information',
            'EmployeeSearch': 'Search Employee',
            'RemarkTitle' : 'Remark?',
            'RemarkSubTitle' : 'Please put remark!',
            'createRevision': 'Create New Revision',
            'updateDateFrom': 'Update Date From',
            'updateDateTo': 'Update Date To',
        },
        'Nav': {
            'Home': 'Home',
            'Ejsa': 'e-JSA',
            'MyTask': 'My Task',
            'TaskHistory': 'My Records',
            'MasterData': 'Master Data',
            'Configure': 'Configure',
            'SystemUser': 'System User',
            'CreateNew': 'Create JSA',
            'CreateNewAttachment': 'Create JSA Attachment',
            'EjsaData': 'e-JSA Data',
            'Site': 'Site',
            'Area': 'Area Authority',
            'ActivityType': 'Activity Type',
            'HazardGroup': 'Hazard Group',
            'Hazard': 'Hazard',
            'TopEvent': 'Top Event',
            'Barriers': 'Barriers',
            'GeneralSafety': 'General Safety',
            'LifeSavingRule': 'LSR/PSR',
            'ContractCompany': 'Contractor Company Name',
            'EjsaSearch': 'Search',
        },
        'dashboard': {
            'menu1': 'Create JSA',
            'menu2': 'My Task',
            'menu3': 'My Records',
            'menu4': 'Search',
            'menu5': 'System User',
            'menu6': 'User Manual',
        },
        'ejsa': {
            'e-JSA Data': 'e-JSA Data',
            'jobStatus': {
                'Draft': 'Draft',
                'Waiting for Contract Holder review': 'Waiting for Contract Holder review',
                'Waiting for review': 'Waiting for review',
                'Waiting for approval': 'Waiting for approval',
                'Approved': 'Approved',
                'Canceled': 'Canceled',
                'Expired': 'Expired',
                'Rejected': 'Rejected',
                'Search': 'Search',
            },
            'Site': 'Site / Asset',
            'Status': 'JSA Status',
            'Area Authority': 'Area Authority',
            'JSA Perform by': 'JSA Perform by',
            'Risk Assessment For': 'Risk Assessment For',
            'Job': 'Job',
            'Area': 'Area',
            'Type of Activity': 'Type of Activity',
            'Job Condition': 'Job Condition',
            'Routine': 'Routine',
            'Non-routine': 'Non-routine',
            'Non-Routine': 'Non-Routine',
            'Emergency': 'Emergency',
            'Area Classification': 'Area Classification',
            'Hazardous Area': 'Hazardous Area',
            'Non-hazardous Area': 'Non-hazardous Area',
            'Unclassified Area': 'Unclassified Area',
            'Job Owner': 'Job Owner',
            'Area Owner': 'Area Owner',
            'JSA Number': 'JSA Number',
            'Job/Area Owner': 'Job/Area Owner',
            'JSA for': 'JSA for',
            'JSAName': 'JSA Name',
            'Staff': 'PTTEP',
            'Contractor': 'Contractor',
            'Contract Holder Review': 'PTTEP Contract Holder or PTTEP Assigned Person',
            'Operation Authority Review': 'Operating Authority',
            'Safe Authority Review': 'Safety Authority',
            'Approver': 'Approver',
            'Contract Company': 'Contractor Company Name',
            'e-JSA List': 'e-JSA List',
            'My Task': 'My Task',
            'Task History': 'My Records',
            'Create New e-JSA': 'Create New e-JSA',
            'Edit e-JSA': 'Update e-JSA',
            'Create New e-JSA Attachment': 'Create New e-JSA Attachment',
            'Contractor Company Name': 'Contractor Company Name',
            'PTTEP Contract Holder or PTTEP Assigned Person': 'PTTEP Contract Holder or PTTEP Assigned Person',
            'Name of Job': 'Name of Job',
            'Name of Area': 'Name of Area',
            'No. working day': 'No. of working day',
            'Complexity of job': 'Complexity of job',
            'Complexity1': 'Simple tasks (< 10 steps per task) with check-off provisions',
            'Complexity2': 'Complex tasks with check-off provisions or simple tasks without check-off provisions',
            'Complexity3': 'Complex tasks (>= 10 steps per task) without check-off provisions ',
            'Stress of job': 'Stress of job',
            'Normal workload': 'Normal workload',
            'Heavy workload': 'Heavy workload',
            'Overload work': 'Overload work',
            'Work Location': 'Work Location',
            'Risk assessment team members': 'Risk assessment team members',
            'General Safety Title': 'General Safety Precaution/Resources required for the job',
            'Life Saving Rule Icon': 'Related  LSR/PSR',
            'Add Step': 'Add Work Step',
            'Work Step': 'Work Step',
            'Work Activity': 'Work Activity',
            'Potential Hazards': 'Hazards Exposure',
            'Top Event': 'Top Event',
            'Initial Risk': 'Initial Risk',
            'BarriersandMitigation': 'Mitigation / Recovery',
            'Residual Risk': 'Residual Risk',
            'Action Party': 'Action Party',
            'Create Activity': 'Create Activity',
            'Create Harzard': 'Create Hazard',
            'Sub Area': 'Sub Area',
            'Add Sub Area': 'Add Sub-Area',
            'Reviewer And Approver': 'Reviewer and Approver',
            'Contract Holder or Assigned Person Review': 'Contract Holder or Assigned Person Review',
            'Operating Authority Review': 'Operating Authority Review',
            'Safety Authority Review': 'Safety Authority Review',
            'Area Authority Approved': 'Area Authority Approved',
            'Save Draft': 'Save Draft',
            'Send Report': 'Submit',
            'Step': 'Work Step',
            'AreaStep': 'Sub-Area',
            'Pos': 'Position',
            'workingDayDesc': '• Total number of workdays from a job’s start date to its end date (Non-routine/Emergency) \r\n • Total number of workdays to execute this routine job in 1 year',
            'data71': 'data71',
            'data72': 'data72',
        },
        'hazard': {
            'Hazard Group': 'Hazard Group',
            'Others': 'Others',
            'Hazard': 'Hazard',
            'Top Event': 'Top Event',
            'Consequence Before': 'Consequence (Before)',
            'Barriers': 'Barriers',
            'Other Barriers': 'Other Barriers',
            'Name': 'Name',
            'Barrier Type': 'Barrier Type',
            'Add': 'Add',
            'Action Party': 'Action Party',
            'Consequence After': 'Consequence (After)',
            'Probability After': 'Probability (After)',
            'Rare': 'Rare',
            'Unlikely': 'Unlikely',
            'Possible': 'Possible',
            'Likely': 'Likely',
            'Almost Certain': 'Almost Certain',
            'Safety Mitigation': 'Safety Mitigation',
            'ADD': 'ADD',
            'Update': 'Update',
            'Delete': 'Delete',
            'Edit': 'Edit',
            'Create Harzard': 'Create Hazard',
            'Update Harzard': 'Update Hazard',
            'incident': 'The work activity was related to the incident (In PTTEP: MTC and above).',
        },
        'workStep': {
            'addAreaStep': 'Add Sub-Area',
            'updateAreaStep': 'Edit Sub-Area',
            'addWorkStep': 'Add Work Step',
            'updateWorkStep': 'Update Work Step',
            'update': 'UPDATE',
            'add': 'Add',
            'title': 'Title',
        },
        'activity': {
            'addActivity': 'Add Activity',
            'updateActivity': 'Update Activity',
            'update': 'UPDATE',
            'add': 'Add',
            'title': 'Title',
        },
        'popup': {
            'confirm': 'Confirm ?',
            'success': 'Success !',
            'dataSave': 'Data has been saved!',
            'error': 'Error !',
            'ok': 'OK',
            'cancel': 'Cancel',
            'yes': 'Yes',
            'no': 'No',
            'noData': 'No Data',
            'pleaseCompleteData': 'Please complete data!',
            'saveRecordAlert': 'Do you want to save this record ?',
            'submitRecordAlert': 'Do you want to submit this record ?',
            'approveRecordAlert': 'Do you want to approve this record ?',
            'reviewRecordAlert': 'Do you want to review this record ?',
            'rejectRecordAlert': 'Do you want to reject this record ?',
            'sendAlert': 'Do you want to send this record ?',
            'draftAlert': 'Do you want to save draft this record ?',
            'deleteAlert': 'Do you want to delete this record ?',
            'cancelAlert': 'Do you want to cancel this record ?',
            'dataSubmited': 'Data has been submited!',
            'dataSaved': 'Data has been saved!',
            'dataApproved': 'Data has been approved!',
            'dataReviewed': 'Data has been reviewed!',
            'dataRejected': 'Data has been rejected!',
            'dataSent': 'Data has been sent!. Your JSA No. #',
            'drafted': 'Data has been saved!. Your JSA No. #',
        },
        'ejsaAttachment': {
            'approvedJsa': 'Approved JSA',
        },
        'revision':{
            'JobRevision': 'Job Revision',
            'Creator': 'Creator',
            'Date': 'Date',
            'Revision': 'Revision',
            'BackToCurrentRevision': 'Back To Current Revision',
            'Current': 'Current',
        }
    }
};
