<mat-toolbar class="p-[0px] mat-elevation-z1">

    <div class="flex flex-row h-full w-full items-center justify-start" style="background-color: #ffffff;">
        <div class="flex flex-1  items-center ">

            <div class="toolbar-separator md:hidden" *ngIf="!hiddenNavbar && !rightNavbar"></div>

            <div class="flex flex-row items-center">
                <div class="logo ml-[16px]">
                    <img alt="Logo" class="logo-icon" src="assets/images/logos/logo2.png">
                </div>
            </div>

        </div>

        <div class="flex flex-row items-center flex-initial">

            <button type="button" mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div class="flex flex-row items-center">
                    <img alt="Profile" class="avatar sm:mr-[16px]" src="assets/images/avatars/profile.jpg">
                    <span class="username mr-[12px] hidden sm:block">{{user?.Name}}</span>
                    <mat-icon class="s-16 sm:block">keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <button type="button" mat-menu-item class="" (click)="doLogout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>{{'main.Logout' | translate}}</span>
                </button>

            </mat-menu>
            <div class="toolbar-separator"></div>

            <button type="button" mat-button class="language-button hidden sm:block" [matMenuTriggerFor]="languageMenu">
                <div class="flex flex-row items-center justify-items-center">
                    <img alt="flags" class="flag mr-[8px]" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>
            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button type="button" mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span class="flex flex-row items-center">
                        <img alt="Flags" class="flag mr-[16px]" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu>

        </div>

    </div>

</mat-toolbar>
